import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Push Jerk 4×6\\@70% 1RM`}</p>
    <p>{`Band Face Pulls 4×12`}</p>
    <p>{`then,`}</p>
    <p>{`4 Rounds for time:`}</p>
    <p>{`400M Row`}</p>
    <p>{`10-S2OH (135/95)(RX+ 165/115)`}</p>
    <p>{`200M Run`}</p>
    <p>{`*`}{`This Saturday is our free Partner WOD at 9:00 & 10:15am so bring a
friend! If you aren’t a member but would like to sign up please contact:
Daniel\\@crossfittheville.org`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      